import { render, staticRenderFns } from "./forget.vue?vue&type=template&id=1c05aabe&scoped=true"
import script from "./forget.vue?vue&type=script&lang=js"
export * from "./forget.vue?vue&type=script&lang=js"
import style0 from "./forget.vue?vue&type=style&index=0&id=1c05aabe&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c05aabe",
  null
  
)

export default component.exports