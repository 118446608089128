<template>
  <page :styleWrap="{'background': '#fff'}">
    <div style="padding: 15px">
      <header-bar>
        <div slot='leading' @click="$router.back()">
          <i style="font-size: 20px" class="iconfont icon-back"></i>
        </div>
      </header-bar>
      <div class="phoneTip">为了您的账户安全，请验证手机号</div>
      <div>
        <van-field class="f_flex f_a_c fieldCell" v-model="phone" label-width="90" type='tel'>
          <div slot='label' style="font-size: 15px;  color: #BABABA">请输入手机号</div>
        </van-field>
        <van-field class="f_flex f_a_c fieldCell" v-model="code" label-width="110" type="tel">
          <div slot='label' style="font-size: 15px;  color: #BABABA">短信验证码</div>
          <template #button>
            <count-down class="theme_c" :state='state' @change='countDownFn'></count-down>
          </template>
        </van-field>
      </div>

      <button class="btn_radius margin_auto register_btn " :class="[phone && code ? 'theme_bg' : 'theme_dis_bg']">下一步</button>
    </div>
  </page>
</template>

<script>
import { Field } from 'vant';
import countDown from '@/components/countDown/index.vue'

export default {
  data: ()=> ({
    phone: '',
    code: '',
    state: false
  }),
  components: {
    'van-field': Field,
    'count-down': countDown
  },
  methods: {
    countDownFn() {
      this.state = true
      setTimeout(()=> {
        this.state = false
      }, 60000)
    }
  },
  created() {
    const {phone} = this.$route.query
    if (phone) {
      this.phone = phone
    }
  }
}
</script>

<style lang="less" scoped>
.phoneTip {
  font-size: 13px;
  color: #666;
  margin-top: 60px;
  margin-bottom: 45px;
}
.fieldCell {
  height: 58px;
  font-size: 15px;
  border-bottom: 1px solid #E4E4E4;
  padding: 0;
}
.fieldLabel {
  color: #BABABA;
  font-size: 15px;
}
.register_btn {
  width: 70%;
  margin-top: 34px;
}
</style>